import { ethers } from 'ethers'
import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { useBalance, useContractRead } from 'wagmi'

import { AlertContainer } from './components/alerts/AlertContainer'
import { BUSD } from './constants/busd'
import { SPIDERX } from './constants/spiderx'
import { useAlert } from './context/AlertContext'
import { useRooms } from './hooks/useRooms'
import { useUser } from './hooks/useUser'
import Game from './routes/Game'
import { RoomsPage } from './routes/RoomsPage'

function App() {
  const { showError: showErrorAlert } = useAlert()
  const { rooms, roomsError } = useRooms()
  const { user, avatar, userError } = useUser()

  const [gameBalance, setGameBalance] = useState('0')
  const [balance, setBalance] = useState('0')

  useBalance({
    address: `0x${user.address.slice(2)}`,
    token: `0x${BUSD.address.slice(2)}`,
    watch: true,
    onSuccess(data) {
      const newBalance = parseFloat(data.formatted).toFixed(2)
      if (newBalance != balance) {
        setBalance(newBalance)
      }
    },
  })

  useContractRead({
    abi: SPIDERX.abi,
    address: `0x${SPIDERX.address.slice(2)}`,
    functionName: 'balances',
    args: [user.address],
    watch: true,
    onSuccess(data) {
      if (data) {
        const newBalance = ethers.utils.formatEther(data.toString())
        if (newBalance != gameBalance) {
          setGameBalance(newBalance)
        }
      }
    },
  })

  useEffect(() => {
    if (roomsError) {
      showErrorAlert(String(roomsError))
    }
    if (userError) {
      if (
        String(userError) === 'AxiosError: Request failed with status code 401'
      ) {
        showErrorAlert('Authorization required.')
      } else {
        showErrorAlert(String(userError))
      }
    }
  }, [roomsError, userError])

  return (
    <div className="flex h-full flex-col">
      <AlertContainer />
      <div className="mx-auto flex w-full grow flex-col px-1 pt-2 pb-8 sm:px-6 md:max-w-7xl lg:px-8 short:pb-2 short:pt-2">
        <Routes>
          <>
            <Route
              path="/"
              element={
                <RoomsPage
                  gameBalance={gameBalance}
                  avatar={avatar}
                  user={user}
                  rooms={rooms}
                />
              }
            />
            {rooms.map((room) => (
              <>
                {room.players.map((player) => (
                  <>
                    {player.address === user.address && player.solution && (
                      <Route
                        path={`game/${room.hash}`}
                        element={
                          <Game
                            key={room.hash}
                            user={user}
                            room={room}
                            player={player}
                          />
                        }
                      />
                    )}
                  </>
                ))}
              </>
            ))}
          </>
        </Routes>
      </div>
    </div>
  )
}

export default App
