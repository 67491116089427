import React, {useEffect, useState} from 'react'
import {ShowRoomModal} from "../components/modals/ShowRoomModal"
import Room from "../models/Room"
import uuid from 'react-uuid';
import {useAlert} from "../context/AlertContext";
import {AlertContainer} from "../components/alerts/AlertContainer";
import {IRoom} from "../models/IRoom";
import {IUser} from "../models/IUser";
import {IPlayer} from "../models/IPlayer";

interface CreateGameProps {
  gameBalance: string
  user: IUser
  avatar: any,
  rooms: IRoom[]
  player?: IPlayer
}

export const CreateGame = ({
    gameBalance,
    user,
    avatar,
    rooms,
    player
                       }: CreateGameProps) => {
  const [room, setRoom] = useState<any>({
    bet: 0,
    description: '',
    fastMode: false,
    game: "wordle",
    hash: uuid(),
    players: [{
      address: user?.address,
      nickname: user?.name,
      lang: '',
      isReady: false,
      solution: '',
      guesses: [],
      stats: {"winDistribution":0,"gamesFailed":0,"currentStreak":0,"bestStreak":0,"totalGames":0,"successRate":0},
    }],
    playersLimit: 0,
    title: user?.name,
  })
  const [description, setDescription] = useState('')
  const [mode, setMode] = useState<string>("fast")
  const [playersLimit, setPlayersLimit] = useState<number>()
  const [bet, setBet] = useState(0)
  const [modalHash, setModalHash] = useState('')
  const [showRoomModalOpen, setIsSowRoomModalOpen] = useState(false)
  const { showError: showErrorAlert, showSuccess: showSuccessAlert } =
      useAlert()
  const create = async () => {

    if (!user.name || !user.address) {
      return showErrorAlert("Authorization required.")
    }

    if (playersLimit == 0) {
      return showErrorAlert("Enter the limit of players in the room.")
    }

    if (!mode) {
      return showErrorAlert("Enter game duration.")
    }

    if (bet <= 0 || bet > parseFloat(gameBalance)) {
      return showErrorAlert(`The bet cannot be equal to 0 or exceed the game balance(${gameBalance}).`)
    }

    if (description.length > 114) {
      return showErrorAlert("Description cannot be longer than 114 characters.")
    }

    let urlRegex = /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/
    let urls = description.match(urlRegex)
    if (urls) {
      return showErrorAlert(`Links in the description are not allowed. Found links: ${urls}.`)
    }

    urlRegex = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/g;
    urls = description.match(urlRegex)
    if (urls) {
      return showErrorAlert(`Links in the description are not allowed. Found links: ${urls}.`)
    }

    const createData =  {
      bet: bet,
      description: description,
      fastMode: mode === "fast" ? true : false,
      game: "wordle",
      hash: uuid(),
      players: [{
        address: user?.address,
        nickname: user?.name,
        lang: '',
        isReady: false,
        solution: '',
        guesses: [],
        stats: {"winDistribution":0,"gamesFailed":0,"currentStreak":0,"bestStreak":0,"totalGames":0,"successRate":0},
      }],
      playersLimit: playersLimit,
      title: user?.name,
    }
    const createResponse = await Room.create(createData)
    if (createResponse["data"]["title"]) {
      setModalHash(createData.hash)
      setIsSowRoomModalOpen(true)
      showSuccessAlert("Room created")
    } else {
      showErrorAlert(`${createResponse["data"]}`)
    }
  }

  useEffect(() => {
    if (rooms) {
      rooms.map(room => {
        if (room.isActive && !room.winner) {
          room.players.map(player => {
            if (player.address === user.address) {
              setRoom(room)
            }
          })
        }
      })
    }
  }, [rooms])

  return (
      <>
        <AlertContainer />
        <ShowRoomModal
            hash={modalHash}
            isOpen={showRoomModalOpen}
            user={user}
            avatar={avatar}
            player={player}
            room={room}
            handleClose={() => setIsSowRoomModalOpen(false)}
        />
        <div
            className="border py-1 px-2 rounded mb-2 dark:text-white"
        >
          <p className="font-bold justify-between">Create Game</p>
          <form>
            <div className="grid gap-2 mb-2 grid-cols-2">
              <div>
                <label htmlFor="playersLimit" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Players limit</label>
                <select id="playersLimit"
                        onChange={e => {
                          setPlayersLimit(parseInt(e.target.value))
                        }}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                </select>
              </div>
              <div>
                <label htmlFor="mode" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Duration</label>
                <select id="mode"
                        onChange={e => {
                          setMode(e.target.value)
                        }}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                  <option value="Fast">15 min</option>
                  <option value="Slow">30 min</option>
                </select>
              </div>
              <div>
                <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Bet</label>
                <input type="number" id="first_name"
                       value={bet}
                       onChange={(event) => setBet(parseInt(event.target.value))}
                       className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                       required/>
              </div>
            </div>
            <div className="mb-2">
              <label htmlFor="last_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
              <input type="text" id="last_name"
                     value={description}
                     onChange={(event) => setDescription(event.target.value)}
                     className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                     required/>
            </div>
            <button type="button" onClick={create}
                    className="w-full block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:bg-slate-800 dark:text-white">
              Create
            </button>
          </form>
        </div>
      </>
  )
}