import React, {useEffect, useState} from 'react'
import {IRoom} from '../models/IRoom'
import Room from "../models/Room"
import {ShowRoomModal} from "../components/modals/ShowRoomModal";
import {IPlayer} from "../models/IPlayer";
import {useAlert} from "../context/AlertContext";
import {AlertContainer} from "../components/alerts/AlertContainer";
import {DEFAULT_BACKEND_URL} from "../constants/default";
import {IUser} from "../models/IUser";
import {useNavigate} from "react-router-dom";
import {useAvatar} from "../hooks/useAvatar";


interface RoomProps {
  gameBalance: string
  player?: IPlayer
  user: IUser
  avatar: any
  room: IRoom
  rooms: IRoom[]
}

export function RoomRow({ room, player, user, gameBalance }: RoomProps) {
  const {avatar} = useAvatar(room.players[0].address)
  const [details, setDetails] = useState(false)
  const [returnButtonShow, setReturnButtonShow] = useState(false)
  const [showRoomModalOpen, setIsSowRoomModalOpen] = useState(false)
  const { showError: showErrorAlert, showSuccess: showSuccessAlert } =
      useAlert()
  const navigate = useNavigate();
  const players = room.players
  const joinGame = async () => {
    if (!user.address || !user.name) {
      return showErrorAlert("Authorization required.")
    }
    if (room.bet > parseFloat(gameBalance)) {
      return showErrorAlert(`To connect to the room, the bet size must not exceed your game balance(${gameBalance})`)
    }
    const joinData: IPlayer = {
      address: user.address,
      status: '',
      nickname: user.name,
      lang: "",
      isReady: false,
      solution: "",
      guesses: [],
      stats: {"winDistribution":[],"gamesFailed":0,"currentStreak":0,"bestStreak":0,"totalGames":0,"successRate":0},
    }

    const joinResponse = await Room.join(room.hash, joinData)
    if (joinResponse.data.title) {
      setIsSowRoomModalOpen(true)
      showSuccessAlert("Connected")
    } else {
      showErrorAlert(`${joinResponse["data"]}`)
    }
  }

  const returnHandleClick = () => {
    if (room.gameIsActive) {
      navigate(`/game/${room.hash}`)
    } else {
      setIsSowRoomModalOpen(true)
    }
  }

  const playerIsConnected = () => {
    let isConnected = false
    players.forEach((player: IPlayer) => {
      if (player.address == user.address) {
        isConnected = true
      }
    })
    setReturnButtonShow(isConnected)
  }

  useEffect(() => {
    playerIsConnected() //
  })
  return (
      <>
        <AlertContainer />
        <ShowRoomModal
            hash={room.hash}
            isOpen={showRoomModalOpen}
            user={user}
            avatar={avatar}
            player={player}
            room={room}
            handleClose={() => setIsSowRoomModalOpen(false)}
        />

        {room.players.length !== 0 && room?.isActive && !room.winner &&
          <>
            <div className="dark:text-white text-sm py-2 flex text-center items-center justify-between bg-white border-b even:bg-gray-50 odd:bg-gray-200 dark:even:bg-gray-800 dark:odd:bg-gray-900 dark:border-gray-700">
              <div className="w-[100px] flex flex-wrap justify-center items-center px-0 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                {/*<div className="flex bg-gray-200 mr-1 inline-block overflow-hidden rounded-full w-[24px] h-[24px]">*/}
                {/*  <img className="justify-center items-center" src={avatar}*/}
                {/*       alt="avatar"/>*/}
                {/*</div>*/}
                <div className="flex justify-center items-center bg-gray-300 inline-block overflow-hidden rounded-full w-[24px] h-[24px]">
                  <img className="rounded-full" src={avatar} alt="creator avatar" />
                </div>
                <p className="text-gray-500 whitespace-normal dark:text-gray-400">{room.title}</p>
              </div>
              <div className="w-[52px]">
                {room.fastMode ? <p>15m</p> : <p>30m</p>}
              </div>
              <div className="w-[65px]">
                {room.players.length}/{room.playersLimit}
              </div>
              <div className="w-[50px]">
                ${room.bet}
              </div>
              <div className="w-[52px]">
                {room.description ?
                    <button type="submit"
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-1 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={() => setDetails(prev => !prev)}>{details ? 'Hide' : 'Show'}
                    </button> :
                    <p>none</p>
                }
              </div>
              <div className="w-[52px]">
                    <>
                      {returnButtonShow ? (
                        <>
                          <button type="submit" onClick={() => returnHandleClick()}
                                  className="text-white bg-yellow-700 hover:bg-yellow-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-0 py-1 text-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800">
                            Return
                          </button>
                        </>
                      ): (
                        <>
                          {room.playersLimit > room.players.length && !room.gameIsActive && (
                              <button type="submit" onClick={() => joinGame()}
                                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-1 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                Join
                              </button>
                          )}
                        </>
                      )}
                    </>
              </div>
            </div>

            {details &&
                <div className="dark:text-white text-center">
                  <p>{ room.description }</p>
                </div>
            }
          </>
        }
      </>
  )
}