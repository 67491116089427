import {useEffect, useState} from 'react'
import {DEFAULT_BACKEND_URL} from "../constants/default";
import axios from "axios";
import defaultImage from "../spider.svg"

export function useAvatar(address: string | undefined) {
  const [avatar, setAvatar] = useState(defaultImage)

  const url = `${DEFAULT_BACKEND_URL}/session`;
  const fetchData = async () => {
    try {
      axios.get(`${DEFAULT_BACKEND_URL}/users/${address}/downloadAvatar`, {responseType: 'arraybuffer'}).then(image => {
        let raw = Buffer.from(image.data).toString('base64');
        const base64 =  "data:" + image.headers["content-type"] + ";base64,"+raw;
        setAvatar(base64)
      })
    } catch (error:any) {
      console.log(error)
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchData().then();
    }, 1000);

    return () => clearTimeout(timer);
  }, [avatar]);

  return { avatar }
}
