import { BaseModal } from './BaseModal'
import React, {useEffect, useState} from "react";
import {IPlayer} from "../../models/IPlayer";
import Room from  "../../models/Room"
import {IRoom} from "../../models/IRoom";
import {useAlert} from "../../context/AlertContext";
import {DEFAULT_BACKEND_URL} from "../../constants/default";
import Countdown from "react-countdown";
import {
    addMinutes, addSeconds
} from 'date-fns'
import { useNavigate } from 'react-router-dom';
import {WORDS} from "../../constants/wordlist"
import {IUser} from "../../models/IUser";
import {saveGameStateToLocalStorage} from "../../lib/localStorage";
import {getIsLatestGame} from "../../lib/words";
import {useAvatar} from "../../hooks/useAvatar";

type ShowRoomModalProps = {
    isOpen: boolean
    handleClose: () => void
    hash: string
    room: IRoom
    user: IUser
    avatar: any
    player?: IPlayer
}

export const ShowRoomModal = ({ isOpen, handleClose, hash, room, user, player, avatar }: ShowRoomModalProps) => {
    const { showError: showErrorAlert, showSuccess: showSuccessAlert } = useAlert()
    const [playersIsReady, setPlayersIsReady] = useState(false)
    const [playersIsLang, setPlayersIsLang] = useState(false)
    const navigate = useNavigate();
    

    useEffect(() => {
        if (room) {
            const playersReady = []
            const playersLang = []
            room.players.forEach(player => {
                if (player.isReady) {
                    playersReady.push(player)
                }
                if (player.lang) {
                    playersLang.push(player)
                }
            })
            if (playersLang.length === room.playersLimit) {
                setPlayersIsLang(true)
            }
            if (playersReady.length === room.playersLimit && playersReady.length !== 0) {
                setPlayersIsReady(true)
            }
        }
    }, [room.players])

    return (
        <div>
            {room?.isActive && (
                <BaseModal title={`${room?.title}'s room`} isOpen={isOpen} handleClose={handleClose}>
                    {playersIsReady && !room.gameIsActive && room.isActive && !room.winner ? (
                        <div className="min-w-[206px] mt-10 mb-10 mr-5 ml-5 flex items-center justify-center w-full h-full">
                            <div className="flex justify-center items-center space-x-1 text-sm text-gray-700">

                                <svg fill='none' className="w-6 h-6 animate-spin" viewBox="0 0 32 32"
                                     xmlns='http://www.w3.org/2000/svg'>
                                    <path clip-rule='evenodd'
                                          d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
                                          fill='currentColor' fill-rule='evenodd'/>
                                </svg>


                                <div>Loading ...</div>
                            </div>
                        </div>
                    ) : (
                        <div className="">
                            { room?.players?.map((player: IPlayer) => <PlayerBox room={room} player={player} user={user} avatar={avatar} handleClose={handleClose} key={player?.address}/>) }
                            {room?.players?.length < room?.playersLimit && (
                                <div
                                    className="text-xs font-medium leading-none text-center text-blue-800 bg-blue-200 rounded-full animate-pulse dark:bg-blue-900 dark:text-blue-200">
                                    Players waiting...
                                </div>
                            )}
                        </div>
                    )}
                </BaseModal>
            )}
        </div>
    )
}

type PlayerProps = {
    player?: IPlayer
    user: IUser
    avatar: any
    room: IRoom
    handleClose: () => void
}

export const PlayerBox = ({ player, room, handleClose, user }: PlayerProps) => {
    const navigate = useNavigate();
    const { showError: showErrorAlert, showSuccess: showSuccessAlert } =
        useAlert()
    const [lang, setLang] = useState("none")
    const {avatar} = useAvatar(player?.address)

    const closeRoom = () => {
        Room.close(room.hash)
        handleClose()
    }

    const leaveRoom = () => {
        Room.leave(room.hash, {address: user?.address})
        handleClose()
    }

    const setReady = () => {
        if (lang !== "none") {
            Room.setReady(room.hash, {address: user?.address})
        } else {
            showErrorAlert("Please select language")
        }
    }

    const handleSetLang = (value: string) => {
        if (!value || value === "none") {
            showErrorAlert("Choose a language to play.")
        }
        setLang(value)
        Room.setLang(room.hash, {address: user?.address, lang: value})
    }

    useEffect(() => {
        if (room.gameIsActive && room.isActive) {
            console.log(room.gameIsActive && room.isActive)
            navigate(`/game/${room.hash}`)
        } else {
            console.log(room)
        }
    }, [room])

    return (
            <>
                {/*<AlertContainer />*/}
                <div className="mb-2 relative border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                    <div className="flex justify-center items-center ">
                        <div className="m-2">
                            <div className="bg-gray-200 inline-block overflow-hidden rounded-full w-[80px] h-[80px]">
                                <img className="" src={avatar} alt="user image" />
                            </div>
                            <div className="flex justify-center dark:text-white">
                                <p className="mr-1 ">
                                    {player?.nickname}
                                </p>
                                {player?.isReady ? (
                                    <div className="flex items-center">
                                        <div
                                            className="z-10 flex items-center justify-center w-6 h-6 bg-green-400 rounded-full dark:bg-green-900 shrink-0">
                                            <svg aria-hidden="true" className="w-4 h-4 text-green-800 dark:text-green-600"
                                                 fill="currentColor" viewBox="0 0 20 20"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd"
                                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                      clip-rule="evenodd"></path>
                                            </svg>
                                        </div>
                                        {player?.lang ? (
                                            <div className="ml-1">
                                                {!lang || lang !== "none" || player?.lang !== "none" ? (
                                                    <div
                                                        className="z-10 flex items-center justify-center w-6 h-6 bg-green-400 rounded-full dark:bg-green-900 shrink-0">
                                                        <p>{player?.lang !== "none" && player?.lang}</p>
                                                    </div>
                                                ) : (
                                                    // <></>
                                                    <div className="flex items-center">
                                                        <div
                                                            className="z-10 flex items-center justify-center w-6 h-6 bg-red-400 rounded-full dark:bg-red-700 shrink-0">
                                                            <svg aria-hidden="true" className="w-3 h-3 text-gray-800 dark:text-gray-300"
                                                                 fill="currentColor" viewBox="0 0 20 20"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd"
                                                                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                                      clip-rule="evenodd"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <div className="ml-1 flex items-center">
                                                <div
                                                    className="z-10 flex items-center justify-center w-6 h-6 bg-red-400 rounded-full dark:bg-red-700 shrink-0">
                                                    <svg aria-hidden="true" className="w-3 h-3 text-gray-800 dark:text-gray-300"
                                                         fill="currentColor" viewBox="0 0 20 20"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd"
                                                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                              clip-rule="evenodd"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="flex items-center">
                                        <div
                                            className="z-10 flex items-center justify-center w-6 h-6 bg-red-400 rounded-full dark:bg-red-700 shrink-0">
                                            <svg aria-hidden="true" className="w-3 h-3 text-gray-800 dark:text-gray-300"
                                                 fill="currentColor" viewBox="0 0 20 20"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd"
                                                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                      clip-rule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    )}
                            </div>
                        </div>

                        {player?.address === user?.address && (
                            <div className="w-[100px] mr-2">
                                <div className="mb-1 mt-2">
                                    <select id="language"
                                            onChange={e => {
                                                handleSetLang(e.target.value)
                                            }}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                        <option value={"none"}>Language</option>
                                        <option value={"en"}>English</option>
                                        <option value={"ru"}>Russian</option>
                                    </select>
                                </div>

                                {player?.isReady ? (
                                    <button
                                        className="mb-1 w-full bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-2 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setReady()}
                                    >
                                        Not ready
                                    </button>
                                ) : (
                                    <button
                                        className="mb-1 w-full bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-2 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setReady()}
                                    >
                                        Ready
                                    </button>
                                )}

                                <div className="mb-2">
                                    {room.players[0].address === player.address ? (
                                        <button
                                            className="w-full bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-2 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => closeRoom()}
                                        >
                                            Close
                                        </button>
                                    ) : (
                                        <button
                                            className="w-full bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-2 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => leaveRoom()}
                                        >
                                            Leave
                                        </button>
                                    )}
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </>
    )
}