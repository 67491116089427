import {CreateGame} from '../components/CreateGame'
import {JoinGame} from '../components/JoinGame'
import React from "react"
import {IRoom} from "../models/IRoom";
import {IUser} from "../models/IUser";
import {IPlayer} from "../models/IPlayer";

interface RoomsPageProps {
    gameBalance: string
    avatar: any
    user: IUser
    rooms: IRoom[]
}

export function RoomsPage (
    {
        gameBalance,
        user,
        avatar,
        rooms,
    }
    : RoomsPageProps) {


  return (
    <div className="container mx-auto max-w-2xl pt-5">
      <CreateGame gameBalance={gameBalance} user={user} avatar={avatar} rooms={rooms}/>
      <JoinGame gameBalance={gameBalance} user={user} avatar={avatar} rooms={rooms}/>
    </div>
  )
}