import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/outline'
import { BaseModal } from './BaseModal'
import Room from "../../models/Room";
import {IRoom} from "../../models/IRoom";
import {IPlayer} from "../../models/IPlayer";

type Props = {
  room: IRoom
  player: IPlayer
  setIsDrawOffersShow: Function
  isOpen: boolean
  handleClose: () => void
}

export const AcceptDrawModal = ({
                             room,
                             player,
                             setIsDrawOffersShow,
                             isOpen,
                             handleClose,

                           }: Props) => {

  return (
      <BaseModal
          title={"Draw offer"}
          isOpen={isOpen}
          handleClose={handleClose}
      >
        <h4 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">
          {"The player offers a draw. You can agree and end the game or continue playing. Do you agree to a draw?"}
        </h4>
          <div className="flex">
              <button
                  type="button"
                  className="mr-2 mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-base"
                  onClick={() => {
                      // Room.setStatus(room.hash, {address: player.address, status: "don't agree to a draw"})
                      setIsDrawOffersShow(false)
                      handleClose()
                    }
                  }
              >
                  <XCircleIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
                  {"NO"}
              </button>
              <button
                  type="button"
                  className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-base"
                  onClick={() => {
                      setIsDrawOffersShow(false)
                      Room.setStatus(room.hash, {address: player.address, status: 'accepts a draw'})
                      handleClose()
                  }}
              >
                  <CheckCircleIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
                  {"YES"}
              </button>
          </div>
      </BaseModal>
  )
}