import {useEffect, useState} from 'react'
import {IRoom} from '../models/IRoom'
import {DEFAULT_BACKEND_URL} from "../constants/default";
import axios from "axios";

export function useRooms() {
  const [rooms, setRooms] = useState<IRoom[]>([])
  const [roomsError, setRoomsError] = useState()

  useEffect(() => {
    const url = `${DEFAULT_BACKEND_URL}/rooms`;
    const fetchData = async () => {
      try {
        const response = await axios.get(url);
        const data = await response.data;
        if (data) {
          setRooms(data)
        }
      } catch (error:any) {
        setRoomsError(error)
        console.error("error", error);
      }
    };
    const timer = setTimeout(() => {
      fetchData().then();
    }, 1000);

    return () => clearTimeout(timer);
  }, [rooms, roomsError]);

  return { rooms, roomsError }
}