import { getGuessStatuses } from '../../lib/statuses'
import { unicodeSplit } from '../../lib/words'
import { Cell } from './Cell'

type Props = {
  isOpponent: boolean
  solution: string
  guess: string
  isRevealing?: boolean
}

export const CompletedRow = ({ solution, guess, isRevealing, isOpponent }: Props) => {
  const statuses = getGuessStatuses(solution, guess)
  const splitGuess = unicodeSplit(guess)

  return (
      <div className="mb-1 flex justify-center">
        {isOpponent ? (
            <>
              {splitGuess.map((letter, i) => (
                    <Cell
                        isOpponent={isOpponent}
                        key={i}
                        value={" "}
                        status={statuses[i]}
                        position={i}
                        isRevealing={isRevealing}
                        isCompleted
                    />
              ))}
            </>
        ) : (
            <>
              {splitGuess.map((letter, i) => (
                  <Cell
                      isOpponent={isOpponent}
                      key={i}
                      value={letter}
                      status={statuses[i]}
                      position={i}
                      isRevealing={isRevealing}
                      isCompleted
                  />
              ))}
            </>
        )}
      </div>
  )
}