import { solution, unicodeSplit } from '../../lib/words'
import { Cell } from './Cell'

type Props = {
  guess: string
  className: string
  isOpponent: boolean
}

export const CurrentRow = ({ guess, className, isOpponent }: Props) => {
  const splitGuess = unicodeSplit(guess)
  const emptyCells = Array.from(Array(solution.length - splitGuess.length))
  const classes = `flex justify-center mb-1 ${className}`

  return (
      <div className={classes}>
        {splitGuess.map((letter, i) => (
            <Cell isOpponent={isOpponent} key={i} value={letter} />
        ))}
        {emptyCells.map((_, i) => (
            <Cell isOpponent={isOpponent} key={i} />
        ))}
      </div>
  )
}