import axios from "axios";
import {IPlayer} from "../models/IPlayer";
import {DEFAULT_BACKEND_URL} from "../constants/default";
import {IRoom} from "../models/IRoom";

export default class Room {
    public static rooms: IRoom[]
    public static setRooms(rooms: IRoom[]) {
        this.rooms = rooms
    }

    public static create(createData: { bet: number; game: string; playersLimit: number | undefined; players: { address: any; solution: string; stats: { currentStreak: number; bestStreak: number; totalGames: number; successRate: number; winDistribution: number; gamesFailed: number }; isReady: boolean; nickname: any; guesses: any[]; lang: string }[]; fastMode: boolean; description: string; title: any; hash: string }): any {
        return axios.post(`${DEFAULT_BACKEND_URL}/rooms/create`, createData).then(res => {
            console.log(res);
            return res
        });
    }

    public static close(hash: string): any {
        return axios.patch(`${DEFAULT_BACKEND_URL}/rooms/${hash}/close`).then(res => {
            console.log(res)
            return res
        })
    }

    public static join(hash: string | undefined, joinData: IPlayer): any {
        return axios.patch(`${DEFAULT_BACKEND_URL}/rooms/${hash}/join`, joinData).then(res => {
            console.log(res)
            return res
        })
    }

    public static leave(hash: string | undefined, leaveData: object): any {
        return axios.patch(`${DEFAULT_BACKEND_URL}/rooms/${hash}/leave`, leaveData).then(res => {
            console.log(res)
            return res
        })
    }

    public static setReady(hash: string, setReadyData: object): any {
        return axios.patch(`${DEFAULT_BACKEND_URL}/rooms/${hash}/setReady`, setReadyData).then(res => {
            console.log(res)
            return res
        })
    }

    public static setLang(hash: string, setLangData: object): any {
        return axios.patch(`${DEFAULT_BACKEND_URL}/rooms/${hash}/setLang`, setLangData).then(res => {
            console.log(res)
            return res
        })
    }

    public static startGame(hash: string | undefined, startGameData: object): any {
        return axios.patch(`${DEFAULT_BACKEND_URL}/rooms/${hash}/startGame`, startGameData).then(res => {
            console.log("startGame: ", res)
            return res
        })
    }

    public static setWinner(hash: string | undefined, address: string): any {
        return axios.patch(`${DEFAULT_BACKEND_URL}/rooms/${hash}/setWinner`, {address: address}).then(res => {
            console.log("setWinner: ", res)
            return res
        })
    }

    public static setGuesses(hash: string | undefined, setGuessesData: {address: string, guess: string}): any {
        return axios.patch(`${DEFAULT_BACKEND_URL}/rooms/${hash}/setGuesses`, setGuessesData).then(res => {
            console.log("setGuesses: ", res)
            return res
        })
    }

    public static setStatus(hash: string, setStatusData: {address: string, status: string}): any {
        return axios.patch(`${DEFAULT_BACKEND_URL}/rooms/${hash}/setStatus`, setStatusData).then(res => {
            console.log("setStatus: ", res)
            return res
        })
    }
}