import { solution } from '../../lib/words'
import { Cell } from './Cell'

interface EmptyRowProps {
    isOpponent: boolean
}

export const EmptyRow = ({
    isOpponent
                         }: EmptyRowProps) => {
  const emptyCells = Array.from(Array(solution.length))

  return (
      <div className="mb-1 flex justify-center">
        {emptyCells.map((_, i) => (
            <Cell isOpponent={isOpponent} key={i} />
        ))}
      </div>
  )
}