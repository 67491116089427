import '../App.css'

import { ClockIcon } from '@heroicons/react/outline'
import {format, differenceInSeconds, parse} from 'date-fns'
import { default as GraphemeSplitter } from 'grapheme-splitter'
import { useEffect, useState } from 'react'
import Div100vh from 'react-div-100vh'

import { AlertContainer } from '../components/alerts/AlertContainer'
import { Grid } from '../components/grid/Grid'
import { Keyboard } from '../components/keyboard/Keyboard'
import { DatePickerModal } from '../components/modals/DatePickerModal'
import { InfoModal } from '../components/modals/InfoModal'
import { MigrateStatsModal } from '../components/modals/MigrateStatsModal'
import { SettingsModal } from '../components/modals/SettingsModal'
import { StatsModal } from '../components/modals/StatsModal'
import { Navbar } from '../components/navbar/Navbar'
import {
    DATE_LOCALE,
    DISCOURAGE_INAPP_BROWSERS,
    LONG_ALERT_TIME_MS,
    MAX_CHALLENGES,
    REVEAL_TIME_MS,
    WELCOME_INFO_MODAL_MS,
} from '../constants/settings'
import {
    CORRECT_WORD_MESSAGE,
    DISCOURAGE_INAPP_BROWSER_TEXT,
    GAME_COPIED_MESSAGE,
    HARD_MODE_ALERT_MESSAGE,
    NOT_ENOUGH_LETTERS_MESSAGE,
    SHARE_FAILURE_TEXT,
    WIN_MESSAGES,
    WORD_NOT_FOUND_MESSAGE,
} from '../constants/strings'
import { useAlert } from '../context/AlertContext'
import { isInAppBrowser } from '../lib/browser'
import {
    getStoredIsHighContrastMode,
    loadGameStateFromLocalStorage,
    saveGameStateToLocalStorage,
    setStoredIsHighContrastMode,
} from '../lib/localStorage'
import { addStatsForCompletedGame, loadStats } from '../lib/stats'
import {
    findFirstUnusedReveal,
    getGameDate,
    getIsLatestGame,
    isWordInWordList,
    setGameDate,
    solutionGameDate,
    unicodeLength,
} from '../lib/words'
import {IUser} from "../models/IUser";
import {IRoom} from "../models/IRoom";
import Countdown from "react-countdown";
import Room from "../models/Room";
import {useNavigate} from "react-router-dom";
import {OfferDrawModal} from "../components/modals/OfferDrawModal";
import {DefeatModal} from "../components/modals/DefeatModal";
import {AcceptDrawModal} from "../components/modals/AcceptDrawModal";

interface GameProps {
    user: IUser,
    room: IRoom
    player: any
}

function Game({user, room, player}:GameProps) {
    const navigate = useNavigate()
    const solution = player.solution.toUpperCase()
    const isLatestGame = getIsLatestGame()
    const gameDate = getGameDate()
    const prefersDarkMode = window.matchMedia(
        '(prefers-color-scheme: dark)'
    ).matches

    const { showError: showErrorAlert, showSuccess: showSuccessAlert } =
        useAlert()
    const [currentGuess, setCurrentGuess] = useState('')
    const [isGameWon, setIsGameWon] = useState(false)
    const [isGameLost, setIsGameLost] = useState(false)
    const [isGameDraw, setIsGameDraw] = useState(false)
    const [isAttemptsEnded, setIsAttemptsEnded] = useState(false)
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
    const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
    const [isDrawModalOpen, setIsDrawModalOpen] = useState(false)
    const [isDrawOffersShow, setIsDrawOffersShow] = useState(true)
    const [isAcceptDrawModalOpen, setIsAcceptDrawModalOpen] = useState(false)
    const [isDefeatModalOpen, setIsDefeatModalOpen] = useState(false)
    const [isDatePickerModalOpen, setIsDatePickerModalOpen] = useState(false)
    const [isMigrateStatsModalOpen, setIsMigrateStatsModalOpen] = useState(false)
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
    const [currentRowClass, setCurrentRowClass] = useState('')
    const [isDarkMode, setIsDarkMode] = useState(
        localStorage.getItem('theme')
            ? localStorage.getItem('theme') === 'dark'
            : prefersDarkMode
                ? true
                : false
    )
    const [isHighContrastMode, setIsHighContrastMode] = useState(
        getStoredIsHighContrastMode()
    )
    const [isRevealing, setIsRevealing] = useState(false)
    const [guesses, setGuesses] = useState(player.guesses)
    const [stats, setStats] = useState(() => loadStats())

    const [isHardMode, setIsHardMode] = useState(
        localStorage.getItem('gameMode')
            ? localStorage.getItem('gameMode') === 'hard'
            : false
    )

    // useEffect(() => {
    //     // if no game state on load,
    //     // show the user the how-to info modal
    //     if (!loadGameStateFromLocalStorage(true)) {
    //         setTimeout(() => {
    //             setIsInfoModalOpen(true)
    //         }, WELCOME_INFO_MODAL_MS)
    //     }
    // })

    useEffect(() => {
        DISCOURAGE_INAPP_BROWSERS &&
        isInAppBrowser() &&
        showErrorAlert(DISCOURAGE_INAPP_BROWSER_TEXT, {
            persist: false,
            durationMs: 7000,
        })
    }, [showErrorAlert])

    useEffect(() => {
        if (isDarkMode) {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }

        if (isHighContrastMode) {
            document.documentElement.classList.add('high-contrast')
        } else {
            document.documentElement.classList.remove('high-contrast')
        }
    }, [isDarkMode, isHighContrastMode])

    useEffect(() => {
        if (isGameWon) {
            const winMessage =
                WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
            const delayMs = REVEAL_TIME_MS * solution.length

            showSuccessAlert(winMessage, {
                delayMs,
                onClose: () => setIsStatsModalOpen(true),
            })
        }

        if (isGameLost) {
            showSuccessAlert("You lose!")
            setTimeout(() => {
                setIsStatsModalOpen(true)
            }, (solution.length + 1) * REVEAL_TIME_MS)
        }

        if (isGameDraw) {
            showSuccessAlert("Draw!")
        }
    }, [isGameWon, isGameLost, isGameDraw, showSuccessAlert])

    useEffect(() => {
        if (player.guesses.length >= MAX_CHALLENGES && !isGameLost) {
            // setIsGameLost(true)
            if (!isAttemptsEnded) showErrorAlert("Attempts ended. Wait for other players.")
            setIsAttemptsEnded(true)
        }
    }, [player.guesses])

    useEffect(() => {
        if (room.players) {
            room.players.map(player => {
                if (player.status) {
                    console.log(player.status)
                    if (room.gameIsActive && isDrawOffersShow && player.status === "offers a draw" && !isAcceptDrawModalOpen && player.address !== user.address) {
                        setIsAcceptDrawModalOpen(true)
                    }
                }
            })
        }
    }, [room.players])

    useEffect(() => {
        if (room.winner) {
            setStats(addStatsForCompletedGame(stats, guesses.length))
            if (room.winner === player.address) {
                return setIsGameWon(true)
            }
            if (room.winner === "draw") {
                return setIsGameDraw(true)
            }
            return setIsGameLost(true)

        }
    }, [room.winner])

    useEffect(() => {
        if (room.gameEndedAt && differenceInSeconds(new Date(), Date.parse(room.gameEndedAt.toString())) >= 1) {
            Room.setWinner(room.hash, "draw")
        }
    }, [room])

    const handleDarkMode = (isDark: boolean) => {
        setIsDarkMode(isDark)
        localStorage.setItem('theme', isDark ? 'dark' : 'light')
    }

    const handleHardMode = (isHard: boolean) => {
        if (guesses.length === 0 || localStorage.getItem('gameMode') === 'hard') {
            setIsHardMode(isHard)
            localStorage.setItem('gameMode', isHard ? 'hard' : 'normal')
        } else {
            showErrorAlert(HARD_MODE_ALERT_MESSAGE)
        }
    }

    const handleHighContrastMode = (isHighContrast: boolean) => {
        setIsHighContrastMode(isHighContrast)
        setStoredIsHighContrastMode(isHighContrast)
    }

    const clearCurrentRowClass = () => {
        setCurrentRowClass('')
    }

    const onChar = (value: string) => {
        if (isGameWon || isGameLost || isGameDraw || !room.gameIsActive) {
            return
        }
        if (
            unicodeLength(`${currentGuess}${value}`) <= solution.length &&
            guesses.length < MAX_CHALLENGES &&
            !isGameWon
        ) {
            setCurrentGuess(`${currentGuess}${value}`)
        }
    }

    const onDelete = () => {
        setCurrentGuess(
            new GraphemeSplitter().splitGraphemes(currentGuess).slice(0, -1).join('')
        )
    }

    const onEnter = () => {
        if (isGameWon || isGameLost || isGameDraw || !room.gameIsActive) {
            return
        }

        if (!(unicodeLength(currentGuess) === solution.length)) {
            setCurrentRowClass('jiggle')
            return showErrorAlert(NOT_ENOUGH_LETTERS_MESSAGE, {
                onClose: clearCurrentRowClass,
            })
        }

        if (!isWordInWordList(currentGuess)) {
            setCurrentRowClass('jiggle')
            return showErrorAlert(WORD_NOT_FOUND_MESSAGE, {
                onClose: clearCurrentRowClass,
            })
        }

        // enforce hard mode - all guesses must contain all previously revealed letters
        if (isHardMode) {
            const firstMissingReveal = findFirstUnusedReveal(currentGuess, guesses)
            if (firstMissingReveal) {
                setCurrentRowClass('jiggle')
                return showErrorAlert(firstMissingReveal, {
                    onClose: clearCurrentRowClass,
                })
            }
        }

        setIsRevealing(true)
        // turn this back off after all
        // chars have been revealed
        setTimeout(() => {
            setIsRevealing(false)
        }, REVEAL_TIME_MS * solution.length)

        if (
            unicodeLength(currentGuess) === solution.length &&
            guesses.length < MAX_CHALLENGES &&
            !isGameWon
        ) {
            if (!guesses.includes(currentGuess)) {
                setGuesses([...guesses, currentGuess])
                Room.setGuesses(room.hash, {address: player.address, guess: currentGuess})
            } else {
                showErrorAlert("Repeated word!")
            }
            if (currentGuess.toUpperCase() === solution.toUpperCase() && !isGameDraw && !isGameWon && isGameLost) {
                setIsGameWon(true)
            }
            setCurrentGuess('')
        }
    }

    return (
        <Div100vh>
            <div className="flex h-full flex-col">
                <Navbar
                    setIsInfoModalOpen={setIsInfoModalOpen}
                    setIsStatsModalOpen={setIsStatsModalOpen}
                    setIsDrawModalOpen={setIsDrawModalOpen}
                    setIsDefeatModalOpen={setIsDefeatModalOpen}
                    setIsDatePickerModalOpen={setIsDatePickerModalOpen}
                    setIsSettingsModalOpen={setIsSettingsModalOpen}
                />

                {room.gameIsActive && (
                    <Countdown
                        className="text-center text-lg font-medium text-gray-900 dark:text-gray-100"
                        date={room.gameEndedAt} //{room.gameEndedAt ? room.gameEndedAt : tomorrow}
                        daysInHours={true}
                        onComplete={() => {
                            if (room.isActive && room.gameIsActive) {
                                Room.setWinner(room.hash, "draw")
                                showSuccessAlert("Time is up, draw!")
                            }
                        }
                        }
                    />
                )}

                {!room.gameIsActive && (
                    <button type="button" onClick={() => navigate("/")}
                            className="flex justify-center text-gray-900 bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-slate-800 dark:text-white dark:focus:ring-gray-500 mr-2 mb-2">
                        Play again!
                    </button>
                )}

                {!isLatestGame && (
                    <div className="flex items-center justify-center">
                        <ClockIcon className="h-6 w-6 stroke-gray-600 dark:stroke-gray-300" />
                        <p className="text-base text-gray-600 dark:text-gray-300">
                            {format(gameDate, 'd MMMM yyyy', { locale: DATE_LOCALE })}
                        </p>
                    </div>
                )}

                <div className="mx-auto flex w-full grow flex-col px-1 pt-2 pb-8 sm:px-6 md:max-w-7xl lg:px-8 short:pb-2 short:pt-2">
                    <div className="flex grow flex-col justify-center pb-6 short:pb-2">
                        <div>
                            <Grid
                                user={user}
                                room={room}
                                solution={solution}
                                guesses={guesses}
                                currentGuess={currentGuess}
                                isRevealing={isRevealing}
                                currentRowClassName={currentRowClass}
                            />
                        </div>
                    </div>
                    <Keyboard
                        onChar={onChar}
                        onDelete={onDelete}
                        onEnter={onEnter}
                        solution={solution}
                        guesses={guesses}
                        isRevealing={isRevealing}
                    />
                    <InfoModal
                        isOpponent={false}
                        isOpen={isInfoModalOpen}
                        handleClose={() => setIsInfoModalOpen(false)}
                    />
                    <OfferDrawModal
                        player={player}
                        room={room}
                        isOpen={isDrawModalOpen}
                        handleClose={() => setIsDrawModalOpen(false)}
                    />
                    <AcceptDrawModal
                        player={player}
                        room={room}
                        setIsDrawOffersShow = {setIsDrawOffersShow}
                        isOpen={isAcceptDrawModalOpen}
                        handleClose={() => setIsAcceptDrawModalOpen(false)}
                    />
                    <DefeatModal
                        room={room}
                        player={player}
                        isOpen={isDefeatModalOpen}
                        handleClose={() => setIsDefeatModalOpen(false)}
                    />
                    <StatsModal
                        isOpen={isStatsModalOpen}
                        handleClose={() => setIsStatsModalOpen(false)}
                        solution={solution}
                        guesses={guesses}
                        gameStats={stats}
                        isLatestGame={isLatestGame}
                        isGameLost={isGameLost}
                        isGameWon={isGameWon}
                        handleShareToClipboard={() => showSuccessAlert(GAME_COPIED_MESSAGE)}
                        handleShareFailure={() =>
                            showErrorAlert(SHARE_FAILURE_TEXT, {
                                durationMs: LONG_ALERT_TIME_MS,
                            })
                        }
                        handleMigrateStatsButton={() => {
                            setIsStatsModalOpen(false)
                            setIsMigrateStatsModalOpen(true)
                        }}
                        isHardMode={isHardMode}
                        isDarkMode={isDarkMode}
                        isHighContrastMode={isHighContrastMode}
                        numberOfGuessesMade={guesses.length}
                    />
                    <DatePickerModal
                        isOpen={isDatePickerModalOpen}
                        initialDate={solutionGameDate}
                        handleSelectDate={(d) => {
                            setIsDatePickerModalOpen(false)
                            setGameDate(d)
                        }}
                        handleClose={() => setIsDatePickerModalOpen(false)}
                    />
                    <MigrateStatsModal
                        isOpen={isMigrateStatsModalOpen}
                        handleClose={() => setIsMigrateStatsModalOpen(false)}
                    />
                    <SettingsModal
                        isOpen={isSettingsModalOpen}
                        handleClose={() => setIsSettingsModalOpen(false)}
                        isHardMode={isHardMode}
                        handleHardMode={handleHardMode}
                        isDarkMode={isDarkMode}
                        handleDarkMode={handleDarkMode}
                        isHighContrastMode={isHighContrastMode}
                        handleHighContrastMode={handleHighContrastMode}
                    />
                    <AlertContainer />
                </div>
            </div>
        </Div100vh>
    )
}

export default Game