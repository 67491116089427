import { MAX_CHALLENGES } from '../../constants/settings'
import { CompletedRow } from './CompletedRow'
import { CurrentRow } from './CurrentRow'
import { EmptyRow } from './EmptyRow'
import {IPlayer} from "../../models/IPlayer";
import React, {useEffect, useState} from "react";
import {IRoom} from "../../models/IRoom";
import {IUser} from "../../models/IUser";
import {DEFAULT_BACKEND_URL} from "../../constants/default";

type Props = {
    solution?: string
    guesses: string[]
    currentGuess: string
    isRevealing: boolean
    currentRowClassName: string
    room?: IRoom
    user: IUser
}

export const Grid = ({
                   solution,
                   guesses,
                   currentGuess,
                   isRevealing,
                   currentRowClassName,
                        user, room
                     }: Props) => {
  const empties =
      guesses.length < MAX_CHALLENGES - 1
          ? Array.from(Array(MAX_CHALLENGES - 1 - guesses.length))
          : []

  return (
      <div className="">
              <div className="flex justify-center">
                  { room?.players?.map((player: IPlayer) => <OpponentsGrids
                      player={player} key={player?.address}
                      isRevealing={isRevealing}
                      currentGuess={currentGuess}
                      currentRowClassName={currentRowClassName}
                      user={user}
                  />) }
              </div>
              {solution && guesses && guesses.map((guess, i) => (
                  <CompletedRow
                      isOpponent={false}
                      key={i}
                      solution={solution}
                      guess={guess}
                      isRevealing={guesses.length - 1 === i}
                  />
              ))}
              {guesses.length < MAX_CHALLENGES && (
                  <CurrentRow isOpponent={false} guess={currentGuess} className={currentRowClassName} />
              )}
              {empties.map((_, i) => (
                  <EmptyRow isOpponent={false} key={i} />
              ))}
      </div>
  )
}

interface OpponentsGridsProps {
    player: IPlayer
    user: IUser
    isRevealing?: boolean
    currentGuess: string
    currentRowClassName: string
}

function OpponentsGrids({
    player, isRevealing, currentGuess, currentRowClassName, user
                        }: OpponentsGridsProps) {
    const [guesses, setGuesses] = useState<string[]>([])
    const [solution, setSolution] = useState<string>("")

    const empties =
        guesses.length < MAX_CHALLENGES - 1
            ? Array.from(Array(MAX_CHALLENGES - 1 - guesses.length))
            : []

    useEffect(() => {
        setGuesses(player.guesses)
        setSolution(player.solution.toUpperCase())
    }, [player])
    return(
        <div className="text-center ml-2 mr-2 mb-2 ">
            {player.address !== user.address && (
                <div className="w-[100px] flex justify-center items-center px-0 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    <div className="mr-1 inline-block overflow-hidden rounded-full w-[28px] h-[28px]">
                        <img className="" src={`${DEFAULT_BACKEND_URL}/users/${player.address}/downloadAvatar`}
                             alt="avatar"/>
                    </div>
                    <p className="text-gray-500 whitespace-normal dark:text-gray-400">{player.nickname}</p>
                </div>
            )}
            {player.address !== user.address && (
                <div className="">
                    {guesses.map((guess, i) => (
                        <CompletedRow
                            isOpponent={true}
                            key={i}
                            solution={solution}
                            guess={guess}
                            isRevealing={false}
                        />
                    ))}
                    {guesses.length < MAX_CHALLENGES && (
                        <CurrentRow isOpponent={true} guess={""} className={""} />
                    )}
                    {empties.map((_, i) => (
                        <EmptyRow isOpponent={true} key={i} />
                    ))}
                </div>
            )}
        </div>
    )
}