import './index.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Chain, WagmiConfig, configureChains, createClient } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { publicProvider } from 'wagmi/providers/public'

import App from './App'
import { AlertProvider } from './context/AlertContext'
import { ModalState } from './context/ModalContext'
import reportWebVitals from './reportWebVitals'

const bnbSmartChain: Chain = {
  id: 97,
  name: 'BNB Smart Chain',
  network: 'BSC',
  nativeCurrency: {
    decimals: 18,
    name: 'BNB',
    symbol: 'BNB',
  },
  rpcUrls: {
    default: { http: ['https://rpc.ankr.com/bsc_testnet_chapel'] },
    public: { http: ['https://rpc.ankr.com/bsc_testnet_chapel'] },
  },
  blockExplorers: {
    default: { name: 'BscScan', url: 'https://testnet.bscscan.com' },
  },
  testnet: true,
}
const { provider, chains, webSocketProvider } = configureChains(
  [bnbSmartChain],
  [
    jsonRpcProvider({
      rpc: () => ({
        http: `https://rpc.ankr.com/bsc_testnet_chapel`,
      }),
    }),
    publicProvider(),
  ]
)

const client = createClient({
  autoConnect: true,
  provider,
  webSocketProvider,
})

ReactDOM.render(
  <WagmiConfig client={client}>
    <BrowserRouter>
      <AlertProvider>
        <ModalState>
          <App />
        </ModalState>
      </AlertProvider>
    </BrowserRouter>
  </WagmiConfig>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
