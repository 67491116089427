import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/outline'
import { BaseModal } from './BaseModal'
import Room from "../../models/Room";
import {IRoom} from "../../models/IRoom";
import {IPlayer} from "../../models/IPlayer";
import {useNavigate} from "react-router-dom";

type Props = {
  room: IRoom
  player: IPlayer
  isOpen: boolean
  handleClose: () => void
}

export const DefeatModal = ({
                             room,
                             player,
                             isOpen,
                             handleClose,

                           }: Props) => {

  const navigate = useNavigate()

  return (
      <BaseModal
          title={"Admit defeat"}
          isOpen={isOpen}
          handleClose={handleClose}
      >
        <h4 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">
          {"Are you going to admit defeat and leave the game?"}
        </h4>
          <div className="flex">
              <button
                  type="button"
                  className="mr-2 mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-base"
                  onClick={() => handleClose()}
              >
                  <XCircleIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
                  {"NO"}
              </button>
              <button
                  type="button"
                  className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-base"
                  onClick={() => {
                      if (room.gameIsActive && room.isActive && !room.winner) {
                          Room.leave(room.hash, {address: player.address})
                          handleClose()
                          navigate("/")
                      } else {
                          handleClose()
                      }
                  }}
              >
                  <CheckCircleIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
                  {"YES"}
              </button>
          </div>
      </BaseModal>
  )
}